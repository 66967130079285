import React, { useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import { BACKEND, getLargeResource } from "../config"
import { request } from "../services/http"
import { HowToCookData } from "../types/controlTypes"
import useOrientation from "../utils/useWindowSize"

export default function HowToCook() {
    const {type} = useParams()
    const [data, setData] = useState<HowToCookData>()
    const [playing, setPlaying] = useState<string>()
    const orientation = useOrientation()
    const videosRef = useRef<Record<string, HTMLVideoElement | null>>({})
    const cardsRef = useRef<HTMLDivElement>(null)

    useEffect(
        () => {
            request(`${BACKEND}/api/database/howtocook`, 'GET')
                .then((data) => {
                   setData(data)
                })
                .catch(({message}) => console.log(message))
        },
        []
    )
    
    // console.group()
    // console.log(orientation)
    // console.groupEnd()

    if (!data) return null

    return <>
        {(!type || !data.validProducts.includes(type)) && 
            <div className="how-to-cook">
                <h1 className="how-to-cook__header t_terrakot t_center">
                    {data.validationError}
                </h1>
            </div>
        }
        <div className="how-to-cook">
            <div
                ref={cardsRef}
                className="how-to-cook__cards"
            >
                {type && data.validProducts.includes(type) &&
                    data.cookingTypes.map(([cookingType, name]) => {
                        const url = getLargeResource(`howtocook/${orientation}/${cookingType}/${type}.mp4`)
                        return (
                            <div
                                key={cookingType}
                                className="how-to-cook__card"
                                onClick={() => videosRef.current[cookingType]?.play()}
                            >
                                {playing !== cookingType &&
                                    <button className='how-to-cook__button'>
                                        <h3 className="how-to-cook__button_title">
                                            {name}
                                        </h3>
                                    </button>
                                }
                                {orientation === 'portrait' && 
                                    <video
                                        key='portrait'
                                        onPlay={() => {
                                            setPlaying(cookingType)
                                            Object.entries(videosRef.current).forEach(([type, video]) => type !== cookingType && video?.pause())
                                        }}
                                        ref={ref => {
                                            if (videosRef.current)
                                                videosRef.current[cookingType] = ref
                                        }}
                                        controls={playing === cookingType}
                                        poster={url.split('.')[0] + '.jpg'}
                                    >
                                        <source src={url}/>
                                    </video>
                                }
                                {orientation === 'album' && 
                                    <video
                                        key='album'
                                        onPlay={() => {
                                            setPlaying(cookingType)
                                            Object.entries(videosRef.current).forEach(([type, video]) => type !== cookingType && video?.pause())
                                        }}
                                        ref={ref => {
                                            if (videosRef.current)
                                                videosRef.current[cookingType] = ref
                                        }}
                                        controls={playing === cookingType}
                                        poster={url.split('.')[0] + '.jpg'}
                                    >
                                        <source src={url}/>
                                    </video>
                                }
                            </div>
                        )
                    })
                }
            </div>
        </div>
    </>
} 
