export const BACKEND = (
    () => process.env.NODE_ENV === 'development'
        ? 'http://localhost:5000'
        : ''
)()
export const RESOURCES = '/api/resources/getimg/'
export const getOther = (title: string) => BACKEND + '/api/resources/getother/' + title.trim().toLowerCase()
export const getResource = (title: string) => BACKEND + '/api/resources/getimg/' + title.trim().toLowerCase()
export const getLargeResource = (title: string) => BACKEND + '/api/resources/getlarge/' + title.trim().toLowerCase()
export const getIcon = (title: string) => BACKEND + '/api/resources/geticon/' + title.trim().toLowerCase()
export const imgplaceholder = () => './plchold.png'
export const resourcePath = (short: string) => BACKEND + RESOURCES + short.trim().toLowerCase()
