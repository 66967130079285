import React, { useEffect, useState } from "react";
import { BACKEND, getResource } from '../config';
import { ReconstructionData } from "../types/controlTypes";
import { request } from "../services/http";
import { Link } from "react-router-dom";
import useOrientation from "../utils/useWindowSize";

export default function Reconstruction() {
    const [data, setData] = useState<ReconstructionData>()
    const orientation = useOrientation()

    useEffect(
        () => {
            request(`${BACKEND}/api/database/reconstruction`, 'GET')
                .then((data) => {
                   setData(data)
                })
                .catch(({message}) => console.log(message))
        },
        []
    )

    if (!data) return null;
    const {button, img, header, toURL} = data
    return (
        <div 
            style={{backgroundImage: `url(${getResource(`reconstruction_${orientation}.jpg`)})`}}
            className='reconstruction'
        >
            <div>
                <h3>
                    {header}
                </h3>
                <h1>
                    <Link to={{pathname: toURL || ''}}>
                        {button}
                    </Link>
                </h1>
            </div>
        </div>
    )
}
