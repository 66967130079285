// import { useEffect, useState } from "react";

// const posterProportion = 0.5625
// export const getOrientation = (width: number, height: number, proportion: number) => {
//     return (width / height) <= proportion
//         ? 'portrait'
//         : 'album'
// }

// export default function useWindowSize(proportion = posterProportion) {
//     const [windowSize, setWindowSize] = useState({
//         width: window.innerWidth,
//         height: window.innerHeight
//     });
//     const [orientation, setOrientation] = useState<'portrait' | 'album'>(getOrientation(
//         window.innerWidth, 
//         window.innerHeight, 
//         proportion
//     ))

//     useEffect(() => {
//         const handleResize = () => {
//             setWindowSize({
//                 width: window.innerWidth,
//                 height: window.innerHeight
//             });
//             setOrientation(getOrientation(
//                 window.innerWidth, 
//                 window.innerHeight, 
//                 proportion
//             ))
//         };

//         window.addEventListener("resize", handleResize);
//         return () => window.removeEventListener("resize", handleResize);
//     }, [proportion]);

//     return [windowSize, orientation] as const;
// };


// const getUseOrientation = () => {
//     return window.screen.orientation?.angle === 0 || window.screen.orientation?.angle === 180
//         ? 'portrait'
//         : 'album';
// };

// export const useOrientation = () => {
//     const [orientation, setOrientation] = useState<'portrait' | 'album'>(getUseOrientation());

//     useEffect(() => {
//         const handleResize = () => {
//             setOrientation(getUseOrientation());
//         };

//         window.addEventListener("orientationchange", handleResize);
//         return () => window.removeEventListener("orientationchange", handleResize);
//     }, []);

//     return orientation;
// };

import { useEffect, useState } from "react";

const getDeviceType = () => {
    const isTouch = navigator.maxTouchPoints > 0;
    const width = window.innerWidth;
    const isMobileUA = /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
    
    if (isMobileUA || (isTouch && width < 1024)) return "mobile" as const;
    return "desktop" as const;
};

const getOrientation = (deviceType: "mobile" | "desktop") => {
    if (deviceType === "mobile" && "orientation" in window.screen) {
        return window.screen.orientation.angle === 0 || window.screen.orientation.angle === 180 ? "portrait" : "album";
    }
    return window.innerWidth / window.innerHeight <= 0.5625 ? "portrait" : "album";
};

const useOrientation = () => {
    const [deviceType, ] = useState(getDeviceType());
    const [orientation, setOrientation] = useState(getOrientation(deviceType));

    useEffect(() => {
        const handleResize = () => setOrientation(getOrientation(deviceType));

        if (deviceType === "mobile") {
            window.addEventListener("orientationchange", handleResize);
        } else {
            window.addEventListener("resize", handleResize);
        }

        return () => {
            if (deviceType === "mobile") {
                window.removeEventListener("orientationchange", handleResize);
            } else {
                window.removeEventListener("resize", handleResize);
            }
        };
    }, [deviceType]);

    return orientation;
};

export default useOrientation;
