import React, { useEffect, useState } from 'react';
import { Provider, useSelector } from 'react-redux';
import {
	Navigate,
	Outlet,
	Route,
	BrowserRouter as Router,
	Routes,
	useLocation,
} from 'react-router-dom';
import FeedBack from './components/FeedBack';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import Login from './components/Login';
import PageLoader from './components/reusable/PageLoader';
import { Popup } from './components/reusable/Popup';
import System from './components/System';
import About from './pages/About';
import Career from './pages/Career';
import Contacts from './pages/Contacts';
import FieldToDish from './pages/FieldToDish';
import Foodservice from './pages/Foodservice';
import FoodserviceDetails from './pages/FoodserviceDetails';
import Home from './pages/Home';
import ReciepeDetails from './pages/ReciepeDetails';
import Reconstruction from './pages/Reconstruction';
import Retail from './pages/Retail';
import Solutions from './pages/Solutions';
import Waste from './pages/Waste';
import { store, TinitialState } from './store';
import HowToCook from './pages/HowToCook';
// import Placeholder from './pages/Placeholder';

export const splitEachString = (array: string[]) =>
	array.map(string => ({
		text: string.split('|')[0],
		img: string.split('|')[1],
	}));

export default function App() {
	return (
		<Provider store={store}>
			<Router>
				<Routes>
					{/* <Route path='/' element={<Placeholder/>}/> */}
					<Route path='*' element={<Navigate to='reconstruction' />} />
					<Route path='/*' element={<Layout />}>
						<Route path='reconstruction' element={<Reconstruction />} />
						<Route path='home' element={<Home />} />
						<Route path='solutions' element={<Solutions />} />
						<Route path='career' element={<Career />} />
						<Route path='contacts' element={<Contacts />} />
						<Route path='foodservice' element={<Foodservice />} />
						<Route path='field_to_dish' element={<FieldToDish />} />
						<Route path='about' element={<About />} />
						<Route path='login' element={<Login />} />
						<Route path='foodservice/:id' element={<FoodserviceDetails />} />
						<Route path='reciepes/:id' element={<ReciepeDetails />} />
						<Route path='retail' element={<Retail />} />
						<Route path='waste' element={<Waste />} />
						<Route path='howtocook/:type' element={<HowToCook />} />
						<Route path='system/:type' element={<System />} />
					</Route>
				</Routes>
			</Router>
		</Provider>
	);
}

function Layout() {
	// const {width, height} = useDevice()
	// const dispatch = useDispatch();
	const { modals } = useSelector((state: TinitialState) => state);
	// const getRealtimeData = async ({ data }: any) => {
	// 	const resp = JSON.parse(data);
	// 	if (resp.state === 'connect') dispatch({ type: 'UPDATE' });
	// };
	const location = useLocation()

	const [ready, setReady] = useState(false)
	// const [blur, setBlur] = useState(false)
	// const { blur } = useSelector((state: TinitialState) => state);

	const scrollTime = 500

	useEffect(() => {
		// setBlur(true)
		setReady(false)
		const anchor = window.location.href.split('#')[1]
		if (anchor) {
			const element = document.querySelector(`#${anchor}`)
			setTimeout(() => element?.scrollIntoView({behavior: 'smooth'}), 500)
		} else
			window.scrollTo({top: 0, behavior: 'smooth'})

		setTimeout(() => {
			// setBlur(false)
			setTimeout(() => setReady(true), 500)
		}, scrollTime)
	}, [location.pathname])

	useEffect(() => {
		Object.values(modals || {}).some(value => value === true)
			? document.body.classList.add('modal-open')
			: document.body.classList.remove('modal-open');
	}, [modals]);

	// useEffect(() => {
	// 	const sse = new EventSource(`${BACKEND}/sseupdate`, {});
	// 	sse.addEventListener('message', getRealtimeData);
	// 	sse.addEventListener('error', console.log);
	// 	return () => {
	// 		sse.removeEventListener('message', getRealtimeData);
	// 		sse.removeEventListener('error', console.log);
	// 	};
	// }, []);
	
	console.log(location.pathname)
	const hideAll = ['reconstruction', 'howtocook'].some(path => location.pathname.startsWith(path))
	return hideAll
		? <div className='page'>
			<Header />
			<div className='underline' />
			{!ready && <PageLoader />}
			<Outlet />
			<Footer />
			<FeedBack open={modals?.feedback} />
			<Popup/>
		</div>
		: <div className='page'>
			{!ready && <PageLoader />}
			<Outlet />
			<Popup/>
		</div>
}
